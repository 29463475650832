const getApiDomain = () => {
	if (window.location.hostname == "payjet-kundenportal.allbytes.run") {
		return "https://payjet-api.allbytes.run";
	} else if (window.location.hostname == "portal.pay-jet.de") {
		return "https://payjet-api.allbytes.run";
	} else if (window.location.hostname == "localhost") {
		//return "https://payjet-api.allbytes.run";
		return "https://payjet-api-staging.allbytes.run";
		//return "http://payjet-api";
	} else {
		return "https://payjet-api-staging.allbytes.run";
	}
}

export default getApiDomain;
